export default class ConnectionTypes {
  constructor() {
      this.items = [
          {
              "id": 1,
              "label": "enum.connection_types.mysql",
              "color": "rgba(242, 145, 17, 0.5)",
              "fields": [
                {
                  "name": "username",
                  "source": "MYSQL.USERNAME",
                  "required": true,
                },
                {
                  "name": "password",
                  "source": "MYSQL.PASSWORD",
                  "required": false
                },
                {
                  "name": "database",
                  "source": "MYSQL.DATABASE",
                  "required": false
                },
                {
                  "name": "host",
                  "source": "MYSQL.HOST",
                  "required": false
                },
                {
                  "name": "port",
                  "source": "MYSQL.PORT",
                  "required": false
                },
                {
                  "name": "ssl",
                  "source": "MYSQL.SSL",
                  "required": false,
                  "type": "switch"
                },
                {
                  "name": "certificate",
                  "source": "MYSQL.CERTIFICATE",
                  "required": false
                },
              ]
          },
          {
              "id": 2,
              "label": "enum.connection_types.postgressql",
              "color": "rgba(45, 104, 143, 0.5)",
              "fields": [
                {
                  "name": "username",
                  "source": "POSTGRESSQL.USERNAME",
                  "required": false
                },
                {
                  "name": "password",
                  "source": "POSTGRESSQL.PASSWORD",
                  "required": false
                },
                {
                  "name": "database",
                  "source": "POSTGRESSQL.DATABASE",
                  "required": false
                },
                {
                  "name": "host",
                  "source": "POSTGRESSQL.HOST",
                  "required": false
                },
                {
                  "name": "port",
                  "source": "POSTGRESSQL.PORT",
                  "required": false
                },
                {
                  "name": "ssl",
                  "source": "POSTGRESSQL.SSL",
                  "required": false
                },
                {
                  "name": "certificate",
                  "source": "POSTGRESSQL.CERTIFICATE",
                  "required": false
                },
              ]
          },
          {
              "id": 3,
              "label": "enum.connection_types.mongo_db",
              "color": "rgba(79, 170, 65, 0.5)",
              "fields": [
                {
                  "name": "database",
                  "source": "MONGO.DATABASE",
                  "required": false
                },
                {
                  "name": "username",
                  "source": "MONGO.USERNAME",
                  "required": false
                },
                {
                  "name": "password",
                  "source": "MONGO.PASSWORD",
                  "required": false
                },
                {
                  "name": "collection",
                  "source": "MONGO.COLLECTION",
                  "required": false
                },
                {
                  "name": "host",
                  "source": "MONGO.HOST",
                  "required": false
                },
                {
                  "name": "port",
                  "source": "MONGO.PORT",
                  "required": false
                },
                {
                  "name": "url",
                  "source": "MONGO.URL",
                  "required": false
                },
                {
                  "name": "certificate",
                  "source": "MONGO.CERTIFICATE",
                  "required": false
                },
              ]
          },
          {
              "id": 4,
              "label": "enum.connection_types.ftp",
              "color": "rgba(123, 26, 179, 0.5)",
              "fields": [
                {
                  "name": "username",
                  "source": "FTP.USERNAME",
                  "required":  false
                },
                {
                  "name": "path",
                  "source": "FTP.PATH",
                  "required": false
                },
                {
                  "name": "password",
                  "source": "FTP.PASSWORD",
                  "required": false
                },
                {
                  "name": "host",
                  "source": "FTP.HOST",
                  "required": false
                },
                
              ]
          },
          {
              "id": 5,
              "label": "enum.connection_types.firebird",
              "color": "rgba(249, 119, 73, 0.5)",
              "fields": [
                {
                  "name": "host",
                  "source": "FIREBIRD.HOST",
                  "required": false
                },
                {
                  "name": "usert",
                  "source": "FIREBIRD.USER",
                  "required":  false
                },
                {
                  "name": "password",
                  "source": "FIREBIRD.PASSWORD",
                  "required": false
                },
                
              ]
          },
          {
              "id": 6,
              "label": "enum.connection_types.sql_server",
              "color": "rgba(209, 2, 16, 0.5)",
              "fields": [
                {
                  "name": "username",
                  "source": "SQL_SERVER.USERNAME",
                  "required": true,
                },
                {
                  "name": "password",
                  "source": "SQL_SERVER..PASSWORD",
                  "required": false
                },
                {
                  "name": "database",
                  "source": "SQL_SERVER.DATABASE",
                  "required": false
                },
                {
                  "name": "host",
                  "source": "SQL_SERVER.HOST",
                  "required": false
                },
                {
                  "name": "port",
                  "source": "SQL_SERVER.PORT",
                  "required": false
                },
                {
                  "name": "ssl",
                  "source": "SQL_SERVER.SSL",
                  "required": false,
                  "type": "switch"
                },
                {
                  "name": "certificate",
                  "source": "SQL_SERVER.CERTIFICATE",
                  "required": false
                },
              ]
          },
          {
              "id": 7,
              "label": "enum.connection_types.sybase",
              "color": "rgba(0, 106, 130, 0.5)",
              "fields": [
                {

                  "name": "username",
                  "source": "SYBASE.USERNAME",
                  "required": true,
                },
                {
                  "name": "password",
                  "source": "SYBASE.PASSWORD",
                  "required": false
                },
                {
                  "name": "database",
                  "source": "SYBASE.DATABASE",
                  "required": false
                },
                {
                  "name": "host",
                  "source": "SYBASE.HOST",
                  "required": false
                },
                {
                  "name": "port",
                  "source": "SYBASE.PORT",
                  "required": false
                },
                {
                  "name": "ssl",
                  "source": "SYBASE.SSL",
                  "required": false,
                  "type": "switch"
                },
                {
                  "name": "certificate",
                  "source": "SYBASE.CERTIFICATE",
                  "required": false
                },
              ]
          },
          {
            "id": 8,
            "label": "enum.connection_types.oracle",
            "color": "rgba(176, 7, 7, 0.5)",
            "fields": [
              {

                "name": "username",
                "source": "ORACLE.USERNAME",
                "required": true,
              },
              {
                "name": "password",
                "source": "ORACLE.PASSWORD",
                "required": false
              },
              {
                "name": "database",
                "source": "ORACLE.DATABASE",
                "required": false
              },
              {
                "name": "host",
                "source": "ORACLE.HOST",
                "required": false
              },
              {
                "name": "port",
                "source": "ORACLE.PORT",
                "required": false
              },
              {
                "name": "ssl",
                "source": "ORACLE.SSL",
                "required": false,
                "type": "switch"
              },
              {
                "name": "certificate",
                "source": "ORACLE.CERTIFICATE",
                "required": false
              },
            ]
        },
      ];
  }
}